import React from "react";
import { Layout, SEO } from '../components/structure';
import { Hero, Button, Content, IconTile, PartnerGrid, CallToAction, IconCallout, BlueBlock,GrayBlock } from "../components/blocks"
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { brand, mq } from "../styles"
import GatsbyImage from "gatsby-image"
import StaggeredColumn from "../components/blocks/StaggeredColumns"
import { Helmet } from "react-helmet"
import CountUp from 'react-countup';

import english from "../../content/translations/en-CA/home.json"
import french from "../../content/translations/fr-CA/home.json"

const StatsCardWrapper = styled.div`
    display: block;
    
    @media screen and ${mq.minSm} {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  `;

const IndexPage = () => {
  let lang = english;
  
  const pageQuery = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-861306766.jpg" }) {
        ...HeroImage
      }
      contentImg1: file(base: { eq: "GettyImages-1151518743.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      memberEngagementGraphic: file(base: { eq: "Homepage-Products.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icon_build: file(base: { eq: "icon-32.png" }) { ...IconTileImage }
      icon_power: file(base: { eq: "icon-33.png" }) { ...IconTileImage }
      icon_grow: file(base: { eq: "icon-34.png" }) { ...IconTileImage }
      icon02: file(base: { eq: "icon-02.png" }) { ...IconTileImage }
      icon03: file(base: { eq: "icon-003.png" }) { ...IconTileImage }
      icon04: file(base: { eq: "icon-04.png" }) { ...IconTileImage }
      icon05: file(base: { eq: "icon-05.png" }) { ...IconTileImage }
      icon06: file(base: { eq: "icon-06.png" }) { ...IconTileImage }
      icon07: file(base: { eq: "icon-07.png" }) { ...IconTileImage }
      icon08: file(base: { eq: "icon-08.png" }) { ...IconTileImage }
      icon09: file(base: { eq: "icon-09.png" }) { ...IconTileImage }
      arrowIcon: file(base: { eq: "arrows.png" }) {
         childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
    }
  `);

  return(
    <Layout>
      <Helmet>
        <meta name="facebook-domain-verification" content="o7hzmqrmnsqg5ackjr8bb7u3v4t47y" />
        <meta name="p:domain_verify" content="c0396a6ed2c931fbe91dbb38cebe02a3"/>
      </Helmet>
      <SEO
        title={lang.meta.title}
        description={
          lang.meta.description}
      />
      <Hero 
        title={lang.hero.title}
        subtitle={<><span style={{ color: "white" }}>{lang.hero.subtitle}</span><br /><Button hero style={{ marginTop: '1rem' }} type={'white'} label={lang.hero.buttonText} destination={'/contact/schedule-a-call/'} /></>}
        image={pageQuery['heroImg']}
      />
      {/* <Content>
        <h1 className={'kl'}>Industry-leading<br />loyalty solutions</h1>
        <p className={'max-w-3xl'}>The world’s leading airline, hotel, and financial services loyalty programs partner with Points to make the movement of loyalty currency simpler and more profitable.</p>
        <p className={'max-w-3xl'}>Every brand wants to enhance its loyalty program, but many are held back by what’s feasible in-house. Points uses 20 years of industry expertise to unlock your program’s full potential and create deeper, more profitable relationships with your members.</p>
      </Content> */}
      <Content angled index>
        <div className="grid grid-3x index">
          <div className="col">
            <IconTile title={lang.content.intro_one.block_one.title} icon={pageQuery['icon_build']} border={false}>
              <p style={{lineHeight: '1.5rem'}}>{lang.content.intro_one.block_one.copy}</p>
            </IconTile>
          </div>
          <div className="col">
            <IconTile title={lang.content.intro_one.block_two.title} icon={pageQuery['icon_power']} border={false}>
              <p style={{lineHeight: '1.5rem'}}>{lang.content.intro_one.block_two.copy}</p>
            </IconTile>
          </div>
          <div className="col">
            <IconTile title={lang.content.intro_one.block_three.title} icon={pageQuery['icon_grow']} border={false}>
              <p style={{lineHeight: '1.5rem'}}>{lang.content.intro_one.block_three.copy}</p>
            </IconTile>
          </div>
        </div>
      </Content>
      <BlueBlock style={{marginBottom: '30px'}}>
        <StatsCardWrapper id={"stats-card-wrapper"}>

          <StatsCard prefix={""} stat={20} suffix={'+'}>
              <p style={{ fontSize: 19, fontWeight: 400 }}>{lang.content.intro_two.block_one.copy}</p>
            </StatsCard>
            <StatsCard prefix={""} stat={92} suffix={"B"}>
              <p style={{ fontSize: 19, fontWeight: 400 }}>{lang.content.intro_two.block_two.copy}</p>
            </StatsCard>
            
            <StatsCard prefix={"$"} stat={3.6} suffix={"B+"} decimal={1}>
              <p style={{ fontSize: 19, fontWeight: 400 }}>{lang.content.intro_two.block_three.copy}</p>
            </StatsCard>
            <StatsCard prefix={""} stat={60} suffix={"+"}>
              <p style={{ fontSize: 19, fontWeight: 400 }}>{lang.content.intro_two.block_four.copy}</p>
            </StatsCard>
        </StatsCardWrapper>
      </BlueBlock>
      
     <GrayBlock>
     <Content whiteOnGray >
        <GatsbyImage fluid={pageQuery['memberEngagementGraphic'].childImageSharp.fluid} style={{ maxWidth: '70%', margin: '185px auto -185px auto', zIndex: 500,transform: 'translateY(-200px)'  }} className='gatsbyHomepageDevices'/>
        <h2 className={'kl'}>{lang.content.intro_three.title}</h2>
        <p style={{  margin: 'auto', width: '70%', paddingBottom: '20px',lineHeight: '1.5rem'}}>{lang.content.intro_three.copy}</p>
        <div className="button-container">
        <Button label={lang.content.intro_three.loyalty_button_text} destination={lang.content.intro_three.loyalty_button_link} type='default' className='index'/>
        <Button label={lang.content.intro_three.everyday_button_text} destination={lang.content.intro_three.everyday_button_link} type='default' className='index'/>
        </div>
        
      </Content>
      <Content greyed style={{marginBottom: '50px'}}>
      <h2 className={'kl'} style={{ margin: '2.5rem 1rem 0rem' }}>{lang.content.intro_four.title}</h2>
        <StaggeredColumn staggered home className="img-block" style={{ paddingBottom: '0px', lineHeight: '1.5rem'}}>
          <IconTile title={lang.content.intro_four.success_block.success_one.title} icon={pageQuery['icon02']} card>
            <p>{lang.content.intro_four.success_block.success_one.copy}</p>
          </IconTile>
          <IconTile title={lang.content.intro_four.success_block.success_two.title} icon={pageQuery['icon06']} card>
            <p>{lang.content.intro_four.success_block.success_two.copy}</p>
          </IconTile>
          <IconTile title={lang.content.intro_four.success_block.success_three.title} icon={pageQuery['icon04']} card>
            <p>{lang.content.intro_four.success_block.success_three.copy}</p>
          </IconTile>
          <IconTile title={lang.content.intro_four.success_block.success_four.title} icon={pageQuery['icon05']} card>
            <p>{lang.content.intro_four.success_block.success_four.copy}</p>
          </IconTile>
          <IconTile title={lang.content.intro_four.success_block.success_five.title} icon={pageQuery['icon03']} card>
            <p>{lang.content.intro_four.success_block.success_five.copy}</p>
          </IconTile>
          <div style={{visibility: 'hidden', marginBottom: '0px', zIndex:'-1'}}>
            <GatsbyImage  fluid={pageQuery['arrowIcon'].childImageSharp.fluid} />
          </div>
        </StaggeredColumn>
        
      </Content>
      <h2 className={'kl'} style={{fontSize: 40}}>{lang.content.partners_heading}</h2>
      <Content whiteOnGray>
      
        <PartnerGrid featured style={{padding: '1rem'}}/>
      </Content>
     </GrayBlock>
      
      <CallToAction title={lang.call_to_action.title}>
      <p  style={{fontSize: 19}}>{lang.call_to_action.copy}</p>
        <Button label={lang.call_to_action.button_text} destination={'/contact/schedule-a-call/'} type={'white'} />

      </CallToAction>
    </Layout>
  );
}

export default IndexPage;

const StatsCardElement = styled.div`
    text-align: center;
    position: relative;
    padding: 10px 20px;
    flex-basis: 100%;
    
    color: #fff;
    font-size: 25px;
    line-height: 1.5rem;
    font-weight: 600;
    align-items: center;
    
    > div {
      width: 100%;
    }

    @media screen and ${mq.minMd} {
      flex-basis: 24%;
      margin-bottom: 0;
      display: inline-block;
    }
    @media screen and ${mq.minSm} {
      flex-basis: 23%;
    padding: 30px 20px;
    }
    span {
      font-size: 50px;
      line-height: 1;
      font-weight: 900;
      left: 0;
      right: 0;
      top: 0;
      color: #fff;
      margin-bottom: 5px;
      display: block;
      @media screen and ${mq.minSm} {
        margin-bottom: 20px;
      }
    }
  `;

const StatsCard = ({ prefix, stat, suffix, decimal, children, ...props }) => (
  <StatsCardElement {...props}>
    <span><CountUp end={stat} enableScrollSpy={true} scrollSpyOnce={true} duration={5} decimals={decimal} prefix={prefix} suffix={suffix} useEasing={true} preserveValue={true}/></span>
    <div>{ children }</div>
  </StatsCardElement>
)
